import { Component, inject, OnInit, PLATFORM_ID, Inject, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ActualiteService } from '../../_services/actualite/actualite.service';
import { Actualite } from '../../_interfaces/actualite';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-actualite-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './actualite-details.component.html',
  styleUrls: ['actualite-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActualiteDetailsComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  actualiteService = inject(ActualiteService);
  sanitizer = inject(DomSanitizer);
  
  actualite?: Actualite;
  sanitizedContent?: SafeHtml;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  cleanContent(content: string): string {
    // Préserve les balises img et autres balises HTML importantes
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Traite chaque nœud de texte
    const walkNodes = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const newText = node.textContent
          ?.replace(/\s*-\s*/g, '-')
          .replace(/\s+/g, ' ')
          .trim();
        if (newText) {
          node.textContent = newText;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach(walkNodes);
      }
    };

    walkNodes(tempDiv);
    return tempDiv.innerHTML;
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.actualiteService.readOneActualite(id).then(actualite => {
        if (actualite && new Date(actualite.date) <= new Date()) {
          this.actualite = actualite;
          const cleanedContent = this.cleanContent(actualite.contenu);
          this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(cleanedContent);
        } else {
          this.router.navigate(['/actualites']);
        }
      });
    });
  }
} 