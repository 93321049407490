import { Component, inject, ViewChild, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { NewsletterService } from '../../_services/newsletter/newsletter.service';
import { ToastComponent } from '../../_components/toast/toast.component';
import { FormsModule } from '@angular/forms';
import { ActualiteService } from '../../_services/actualite/actualite.service';
import { EvenementService } from '../../_services/evenement/evenement.service';
import { Actualite } from '../../_interfaces/actualite';
import { Evenement } from '../../_interfaces/evenement';
import { ActualiteCardComponent } from '../../_components/actualite-card/actualite-card.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, ToastComponent, FormsModule, ToastComponent, FormsModule, RouterModule, ActualiteCardComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  constructor(
    
    private metaService: Meta,
   
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  @ViewChild(ToastComponent) toast!: ToastComponent;


  evenementService = inject(EvenementService);
  newsletterService = inject(NewsletterService);
  carouselElement!: HTMLElement;
  email!: string;
  actualiteService = inject(ActualiteService);
  
  evenements: Evenement[] = [];
  actualites: Actualite[] = [];
  
  private readonly PREVIEW_LENGTH = 150;

  ngOnInit() {
    this.updateMeta();
    this.retrieve_evenements();
    this.retrieve_actualites();
  }

  stripHtml(html: string): string {
    if (isPlatformBrowser(this.platformId)) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      const text = tmp.textContent || tmp.innerText || '';
      
      if (text.length <= this.PREVIEW_LENGTH) {
        return text;
      }
      
      return text.slice(0, this.PREVIEW_LENGTH) + '...';
    }
    // Fallback pour le rendu côté serveur
    const text = html.replace(/<[^>]*>/g, '');
    if (text.length <= this.PREVIEW_LENGTH) {
      return text;
    }
    return text.slice(0, this.PREVIEW_LENGTH) + '...';
  }

  updateMeta(){
    // Définir le titre de la page
    this.titleService.setTitle('La Bourrée de Paris');

    // Définir les balises meta pour SEO
    this.metaService.addTags([
      { name: 'description', content: "La Bourrée de Paris : groupe folklorique auvergnat à Paris, offrant danse, musique et chants traditionnels depuis 1925 pour tous types d'événements." },
      { name: 'keywords', content: 'groupe folklorique, traditions auvergnates, danse traditionnelle, musique auvergnate, chant auvergnat, La Bourrée de Paris, spectacle folklorique, groupe de folklore Paris, patrimoine culturel, animation événementielle' },
      { name: 'robots', content: 'index,follow' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'La Bourrée de Paris' },
      { property: 'og:description', content: 'Rejoignez-nous pour une expérience immersive dans la tradition auvergnate avec La Bourrée de Paris, offrant des spectacles de danse, musique et chant depuis 1925.' },
      { property: 'og:image', content: 'assets/images/photo-4.webp' },
      { property: 'og:url', content: 'https://www.labourree.com' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'La Bourrée de Paris' },
      { name: 'twitter:description', content: 'Rejoignez-nous pour une expérience immersive dans la tradition auvergnate avec La Bourrée de Paris, offrant des spectacles de danse, musique et chant depuis 1925.' },
      { name: 'twitter:image', content: 'assets/images/photo-4.webp' }
      // Plus d'autres balises que vous pourriez vouloir ajouter
    ]);
  }

  retrieve_evenements(){
    // Récupérer les événements
    this.evenementService.readXEvenements(3).subscribe(evenements => {
      this.evenements = evenements;
    });
  }

  onNewsletterSubmit() {
    if(this.email) {
      this.showToast(`Merci de vous être inscrit à la newsletter avec l'adresse ${this.email}`, 'success');
      this.newsletterService.createNewsletter(this.email);
    }
    this.email = '';
  }

  showToast(str: string, type: 'success' | 'error' | 'info' = 'info') {
    this.toast.showToast(str, type);
  }

  retrieve_actualites() {
    this.actualiteService.readPublishedActualites().subscribe(
      actualites => {
        this.actualites = actualites.slice(0, 3);
      },
      error => console.error('Erreur lors de la récupération des actualités:', error)
    );
  }
}
