import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActualiteService } from '../../_services/actualite/actualite.service';
import { Actualite } from '../../_interfaces/actualite';
import { ActualiteCardComponent } from '../../_components/actualite-card/actualite-card.component'

@Component({
  selector: 'app-actualites',
  standalone: true,
  imports: [CommonModule, ActualiteCardComponent],
  template: `
    <section class="container py-8">
      <h1 class="text-4xl font-bold mb-8">Actualités</h1>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        @for(actualite of actualites; track actualite.key) {
          <app-actualite-card [actualite]="actualite"></app-actualite-card>
        }
      </div>
    </section>
  `
})
export class ActualitesComponent {
  actualiteService = inject(ActualiteService);
  actualites: Actualite[] = [];

  constructor() {
    this.readActualites();
  }

  readActualites() {
    this.actualiteService.readPublishedActualites().subscribe(
      actualites => this.actualites = actualites,
      error => console.error('Erreur lors de la récupération des actualités:', error)
    );
  }
}
