export enum TagType {
  DANSE = 'DANSE',
  MUSIQUE = 'MUSIQUE',
  SPECTACLE = 'SPECTACLE',
  EVENEMENT = 'EVENEMENT',
  ASSOCIATION = 'ASSOCIATION'
}

export interface TagConfig {
  label: string;
  color: string;
}

export const TAG_CONFIG: Record<TagType, TagConfig> = {
  [TagType.DANSE]: { label: 'Danse', color: '#8B5CF6' },
  [TagType.MUSIQUE]: { label: 'Musique', color: '#EC4899' },
  [TagType.SPECTACLE]: { label: 'Spectacle', color: '#3B82F6' },
  [TagType.EVENEMENT]: { label: 'Evenement', color: '#10B981' },
  [TagType.ASSOCIATION]: { label: 'Association', color: '#6366F1' }
}; 