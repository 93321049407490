import { Component, Input, PLATFORM_ID, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Actualite } from '../../_interfaces/actualite';
import { TagType, TAG_CONFIG } from '../../_enums/tag.enum';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-actualite-card',
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    <div class="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      @if(actualite.image) {
        <div class="relative h-48 overflow-hidden">
          <img [src]="actualite.image" 
               [alt]="actualite.titre" 
               class="w-full h-full object-cover transition-transform duration-300 hover:scale-105">
        </div>
      }
      <div class="p-6">
        <h3 class="text-xl font-semibold mb-2 text-blue-dark">{{ actualite.titre }}</h3>
        @if(actualite.tags && actualite.tags.length > 0) {
          <div class="flex flex-wrap gap-2 mb-3">
            @for(tag of actualite.tags; track tag) {
              <span 
                class="px-2 py-0.5 text-xs font-medium rounded-full text-white"
                [style.background-color]="getTagColor(tag)">
                {{ getTagLabel(tag) }}
              </span>
            }
          </div>
        }
        <p class="text-sm text-gray-500 mb-3">
          {{ actualite.date | date:'longDate':'':'fr' }}
        </p>
        <p class="text-gray-600 mb-4 whitespace-normal break-words overflow-wrap-anywhere line-clamp-3">
          {{ stripHtml(actualite.contenu) }}
        </p>
        <a [routerLink]="['/actualites', actualite.key]" 
           class="inline-flex items-center text-primary hover:text-secondary">
          Lire la suite
          <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </div>
  `
})
export class ActualiteCardComponent {
  @Input() actualite!: Actualite;
  tagConfig = TAG_CONFIG;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  stripHtml(html: string): string {
    let text: string;
    
    if (isPlatformBrowser(this.platformId)) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      text = tmp.textContent || tmp.innerText || '';
    } else {
      text = html.replace(/<[^>]*>/g, '');
    }

    // Nettoie le texte de la même manière que dans ActualiteDetails
    return text
      .replace(/\s*-\s*/g, '-')
      .replace(/\n\s*\n/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  }

  getTagColor(tag: TagType): string {
    return this.tagConfig[tag].color;
  }

  getTagLabel(tag: TagType): string {
    return this.tagConfig[tag].label;
  }
} 