<!-- ORIGINE -->
<section class="py-20 px-5 lg:px-20 bg-white">
    <div class="container flex flex-col lg:flex-row gap-10">
        <div class="flex flex-col gap-4 font-raleway grow">
            <h2 class="text-4xl lg:text-6xl font-alice">La Bourrée de Paris</h2>
            <p>Fondée en 1925, La Bourrée de Paris a vu le jour au sein de la colonie des auvergnats de la capitale. Sous l’impulsion des frères Oustry, Louis Bonnet crée la “société artistique La Bourrée”. Connaissant un succès fulgurant dans l’ensemble des disciplines y étant enseignées, ce sont des maîtres des arts et lettres ainsi que de la musique qui apportent leurs collaborations au projet. Ils deviennent, à cette occasion les garants du collectage, de la diffusion et de la transmission de nos arts et traditions populaires. À sa création, Camille Gandilhon Gens d’Armes, Joseph Canteloube et Martin Cayla en sont les principaux animateurs du groupe.</p>
            <p>Filiale de la ligue Auvergnate et du massif central le groupe represente à travers les costumes, musiques, chants et danses l’ensemble des départements et régions qui y sont rattachés. Tantôt bourgeois, tantôt paysans, c’est toute la richesse et la diversité de nos territoires qui est magnifiées dans nos spectacles.</p>
            <p>La Bourrée de Paris est fortement impliquée dans le réseau des Arts et Traditions de par son engagement au sein de fédération nationale. Depuis plus de 20 ans elle reçoit le label CIOFF, gage de qualité et qui nous autorise une recommandation auprès des instances nationales et mondiales.</p>
            <p>A travers nos danseurs, musiciens et chanteurs, nous offrons un spectacle riche en diversité, représentant l’authenticité de chaque village de notre terroir. Des danses de salon mondain aux rythmes soutenus de la bourrée, notre performance est un hommage vibrant à notre culture. </p>
            <p>Nos costumes, témoins de notre héritage, reflètent la diversité de nos départements. Accompagnés de l’accordéon, la cabrette et la vielle, nos artistes captivent le public, tandis que notre chorale perpétue l’usage de la langue d’Oc.</p>
            <p>Composée de jeunes talents, à partir de 16 ans, notre formation a participé à de nombreux festivals français et internationaux en parcourant plus de 30 pays. De Tahiti au Japon, du Mexique à la Suède, nous portons fièrement le drapeau de notre Province. Par ailleurs, elle apparait dans quelques films et de nombreuses émissions télévisées. Plus récemment, elle sera vue par des millions de téléspectateurs lors de l’ouverture des Jeux Olympiques PARIS 2024.</p>
            <p>Avec sa jeunesse enthousiaste et fidèle aux racines, la Bourrée de Paris, bientôt centenaire, est plus que jamais prête à chanter et danser pour vous son “Pays Natal”. </p>
        </div>
        <img src="assets/images/photo-17.jpg" alt="Photo représentant un membre de la Bourrée de Paris" class="lg:w-1/3 w-full object-cover">
    </div>
</section>

<!-- Nos Costumes -->
<section class="py-20 px-5 lg:px-20 bg-white-tinted">
    <div class="container flex flex-col-reverse lg:flex-row gap-10">
        <img src="assets/images/photo-16.jpg" alt="Photo représentant les costumes" class="lg:w-1/3 w-full object-cover">
        <div class="flex flex-col gap-4 font-raleway">
            <h2 class="text-4xl lg:text-6xl font-alice">Nos costumes</h2>
            <p>Depuis notre fondation, nous avons fait de nos costumes une véritable œuvre d'art, reflétant avec précision l'élégance et la diversité des tenues traditionnelles du Massif Central. De 1800 à 1914, cette période charnière nous a révélé des trésors visuels grâce à des tableaux, des dessins et une précieuse collecte de données sur les vêtements d'époque.</p>
            <p>Imaginez-vous transportés dans le temps, où chaque détail de nos costumes vous plonge dans l'atmosphère enchanteresse du XIXe siècle. Nos jeunes danseuses et danseurs, issus des différents départements du Massif Central, incarnent avec grâce et élégance l'authenticité de notre patrimoine culturel.</p>
            <p>Des corsets délicatement brodés aux vestes à basque austèrement chic, chaque pièce de nos costumes raconte une histoire, témoignant de l'évolution des modes et des coutumes à travers les âges. Mais notre passion pour l'histoire ne s'arrête pas là : elle se prolonge à travers nos coiffes ornées, nos robes « à babarel » et nos célèbres « caracos », autant de symboles d'un savoir-faire artisanal préservé avec amour.</p>
            <p>Plongez dans l'univers infiniment riche de nos costumes, où chaque département du Massif Central apporte sa propre touche de créativité et d'originalité. Des riches collets de la Haute-Loire aux chapeaux « tricorne » élégamment posés sur un bonnet de laine dans la région de Langeac, laissez-vous séduire par la diversité et la beauté de nos traditions.</p>
            <p>Mais nos costumes ne sont pas seulement des témoins du passé : ils incarnent également le dynamisme et la vitalité de notre groupe, résolument tourné vers l'avenir. En perpétuant ces traditions séculaires, nous contribuons à préserver l'âme et l'identité de notre région, tout en offrant un spectacle moderne et captivant à notre public.</p>
        </div>
    </div>
</section>

<!-- Nos danses  -->
<section class="py-20 px-5 lg:px-20 bg-white">
    <div class="container flex flex-col gap-10">
        <div class="flex flex-col gap-4 font-raleway">
            <h2 class="text-4xl lg:text-6xl font-alice">Nos danses</h2>
            <p>La bourrée, cette danse envoûtante trouve ses racines dans les différents départements du Massif Central. Son histoire ancienne a inspiré de nombreux compositeurs séduits par son rythme entraînant et sa diversité. </p>
            <p>Selon sa localisation et sa vocation, la bourrée se métamorphose, révélant des figures et des rythmes variés. Des montagnes aux plaines, des villages reculés aux villes, chaque région apporte sa touche distinctive à cette danse emblématique.</p>
            <p>Dans les contrées montagneuses, la bourrée se pare de simplicité et d’énergie, reflétant le caractère populaire de ses origines. Plus près des centres urbains, elle se pare de sophistication, avec des figures élaborées et un rythme plus mesuré, témoignant d’une noblesse retrouvée.</p>
            <p>La vie quotidienne devient une célébration avec la bourrée comme guide. De la maison à la foire, en passant par les jeux et les festivités, chaque moment est propice à la danse et à la fête. Des noms évocateurs tel que  “l’Escloupeto”, la “bourrée des bouteilles” ou le “Solto l’Aze” transportent les danseurs dans un tourbillon de traditions et d’émotions. </p>
            <p>Plongez dans l’univers envoûtant de la bourrée, où chaque pas raconte une histoire et chaque rythme éveille les sens.</p>
        </div>
    </div>
</section>

<!-- Nos Spectacles -->
<section class="py-20 px-5 lg:px-20 bg-white-tinted">
    <div class="container flex flex-col lg:flex-row gap-10 items-center">
        <div class="flex flex-col gap-4 font-raleway w-full lg:w-2/3 ">
            <h2 class="text-4xl lg:text-6xl font-alice">Nos spectacles</h2>
            <p>Plongez dans l'univers envoûtant de la danse traditionnelle française avec nos spectacles captivants, où la Bourrée auvergnate prend vie sous un nouvel angle contemporain !</p>
            <p>Au cœur de nos représentations, découvrez l'essence même de la Bourrée, une danse empreinte d'histoire et de tradition, réinventée pour ravir les sens et captiver l'imagination. Autrefois réservée aux hommes, la Bourrée se métamorphose sous nos yeux, mêlant avec grâce la fierté virile à la délicatesse féminine, pour un spectacle empreint d'émotion et de puissance.</p>
            <p>Nos chorégraphies, fruit d'une recherche minutieuse des différentes formes et variations de la Bourrée à travers le Massif Central, vous transportent dans un tourbillon de mouvements légers, rapides et expressifs. De la force brute des pas frappés des hommes à la grâce aérienne des pas valsés des demoiselles, laissez-vous emporter par la magie de cette danse aux multiples facettes.</p>
            <p>Mais nos spectacles ne s'arrêtent pas là. En associant la Bourrée à d'autres danses emblématiques telles que la valse, la polka et la mazurka, nous vous offrons une expérience unique, riche en contrastes et en émotions. Explorez avec nous les thèmes universels de la rivalité entre ville et campagne, de l'amour passionné et des dures réalités de la vie rurale, le tout sublimé par la puissance évocatrice de la danse.</p>
        </div>
        <div class="w-full lg:w-1/3 aspect-video">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/BUzcx6S-AwI?si=RVzG7Zmfq14-dPX3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
    </div>
</section>

<!-- Nos CHants -->
<!-- <section class="bg-white py-20">
    <div class="container flex flex-row gap-10">
        <h2 class="text-4xl lg:text-6xl font-alice">Nos chants</h2>
    </div>
</section> -->

<!-- Nos Musique -->
<!-- <section class="bg-white-tinted py-20">
    <div class="container flex flex-row gap-10">
        <h2 class="text-4xl lg:text-6xl font-alice">Nos musiques</h2>
    </div>
</section> -->