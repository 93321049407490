import { Routes } from '@angular/router';
import { HomeComponent } from './_pages/home/home.component';
import { LoginComponent } from './_pages/login/login.component';
import { PageNotFoundComponent } from './_pages/page-not-found/page-not-found.component';
import { AdminComponent } from './_pages/admin/admin.component';
import { AdminGuard } from './_guards/admin/admin.guard';
import { AuthGuard } from './_guards/auth/auth.guard';
import { AgendaComponent } from './_pages/agenda/agenda.component';
import { RegisterComponent } from './_pages/register/register.component';
import { EvenementEditionComponent } from './_pages/_admin/evenement-edition/evenement-edition.component';
import { EvenementsDetailsComponent } from './_pages/evenements-details/evenements-details.component';
import { RessourcesComponent } from './_pages/ressources/ressources.component';
import { UtilisateurEditionComponent } from './_pages/_admin/utilisateur-edition/utilisateur-edition.component';
import { NewsletterComponent } from './_pages/_admin/newsletter/newsletter.component';
import { ActualitesComponent } from './_pages/actualites/actualites.component';
import { ActualiteEditionComponent } from './_pages/_admin/actualite-edition/actualite-edition.component';
import { ActualiteDetailsComponent } from './_pages/actualite-details/actualite-details.component';
import { AProposComponent } from './_pages/a-propos/a-propos.component';

export const routes: Routes = [
    {path: "", component: HomeComponent},
    {path: "login", component: LoginComponent},
    {path: "register", component: RegisterComponent},
    {path: "a-propos", component: AProposComponent},
    {path: "admin", component: AdminComponent, canActivate: [AdminGuard]},
    {path: "admin/evenements", component: EvenementEditionComponent, canActivate: [AdminGuard]},
    {path: "admin/utilisateurs", component: UtilisateurEditionComponent, canActivate: [AdminGuard]},
    {path: "admin/newsletter", component: NewsletterComponent, canActivate: [AdminGuard]},
    {path: "admin/actualites", component: ActualiteEditionComponent, canActivate: [AdminGuard]},
    {path: "agenda", component: AgendaComponent, canActivate: [AuthGuard]},
    {path: "actualites", component: ActualitesComponent},
    {path: "actualites/:id", component: ActualiteDetailsComponent},
    {path: "ressources", component: RessourcesComponent, canActivate: [AuthGuard]},
    {path: "agenda/:id", component: EvenementsDetailsComponent, canActivate: [AuthGuard]},
    {path: "**", component: PageNotFoundComponent},
];
