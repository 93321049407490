<div class="rounded-lg overflow-hidden shadow transition-shadow duration-300 ease-in-out hover:shadow-md bg-[#F5F5F5]">
    <!-- Image de l'événement -->
    
    @if (evenement.image){
        <img class="w-full h-48 object-cover" src="{{ evenement.image }}" alt="Image de l'événement">
    }
    @else{
        <img class="w-full h-48 object-cover" src="assets/images/photo-1.jpg" alt="Image de l'événement">
    }

    <!-- Détails de l'événement -->
    <div class="p-5">
        <h3 class="text-lg font-semibold text-gray-800 mb-3">{{ evenement.nom }}</h3>
        <!-- <p class="text-sm text-gray-600 mb-4">
            {{ evenement.description | truncate:100 }}
        </p> -->
        <div class="text-gray-500 text-xs uppercase mb-4">
            <strong>{{ evenement.dateDebut | date:'d MMM': 'Europe/Paris': 'fr-FR' }}
            @if(sameDate){ {{ evenement.dateDebut | date:"HH'h'mm": 'Europe/Paris': 'fr-FR' }} }
            @else{ - {{ evenement.dateFin | date:'d MMM': 'Europe/Paris': 'fr-FR' }}  }
            </strong>
        </div>
        <div class="flex justify-between items-center">
            <span class="text-sm text-gray-500 flex items-center">
                <img class="h-5 w-5" src="./assets/icon/location.svg" alt="Icone de Localisation">
                {{ evenement.lieu }}
            </span>
            <div class="flex gap-2 items-center">
                <!-- Boutons d'action -->
                @if(evenement.key){
                    <button (click)="setInscription(evenement.key, true)" class="p-2 rounded-full border-2"
                    [ngClass]="{'text-white bg-green-500 hover:bg-green-600' : inscription && inscription.is_present,
                                'text-green-500 bg-transparent hover:bg-transparent border-green-400': (inscription && !inscription.is_present) || !inscription}">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    </button>
                    <button (click)="setInscription(evenement.key, false)" class="p-2 rounded-full border-2"
                    [ngClass]="{'text-white bg-red-500 hover:bg-red-600' : inscription && !inscription.is_present,
                                'text-red-500 bg-transparent hover:bg-transparent border-red-400': (inscription && inscription.is_present) || !inscription}">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                    <a href="/agenda/{{ evenement.key }}" class="p-2 rounded-full border-2 border-blue-500 bg-blue-500 hover:bg-blue-600 text-white">
                        <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 12h16M12 4l8 8-8 8"></path>
                        </svg>                        
                    </a>
                }
            </div>
        </div>
    </div>
</div>