<div class="container mx-auto p-4">
  <!-- Formulaire existant -->
  <form [formGroup]="actualiteForm" (ngSubmit)="onSubmit()" class="mb-8">
    <div>
        <label class="block text-sm font-medium text-gray-700">Titre</label>
        <input type="text" formControlName="titre"
               class="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
    </div>
    <div>
        <label class="block text-sm font-medium text-gray-700">Contenu</label>
        <quill-editor #editor 
                      formControlName="contenu" 
                      [modules]="modules"
                      class="h-[500px] bg-white w-full">
        </quill-editor>
        <p class="mt-2 text-sm text-gray-500">
          Astuce : Sélectionnez une image pour afficher les options d'alignement
        </p>
    </div>
    <div>
        <label for="date" class="block text-sm font-medium text-gray-700">Date de publication :</label>
        <input type="date" 
               id="date" 
               formControlName="date"
               class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
    </div>
    <div>
        <label for="auteur" class="block text-sm font-medium text-gray-700">Auteur :</label>
        <input type="text" id="auteur" formControlName="auteur" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
    </div>
    <div>
        <label class="block text-sm font-medium text-gray-700">Image</label>
        <div class="mt-1 flex items-center space-x-4">
            <input type="file" 
                   accept="image/*"
                   (change)="onImageSelected($event)"
                   class="block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-full file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100">
            
            @if(actualiteForm.get('imagePreview')?.value || actualite?.image) {
                <img [src]="actualiteForm.get('imagePreview')?.value || actualite?.image" 
                     alt="Prévisualisation" 
                     class="h-20 w-20 object-cover rounded">
            }
        </div>
    </div>
    <div class="mt-4">
      <label class="block text-sm font-medium text-gray-700">Tags</label>
      <div class="mt-2 flex flex-wrap gap-2">
        @for(tagType of tagTypes; track tagType) {
          <button 
            type="button"
            (click)="toggleTag(tagType)"
            [class.opacity-40]="!isTagSelected(tagType)"
            class="px-3 py-1 text-sm rounded-full text-white transition-opacity duration-200"
            [style.background-color]="tagConfig[tagType].color">
            {{ tagConfig[tagType].label }}
          </button>
        }
      </div>
    </div>
    <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Soumettre</button>
  </form>

  <!-- Liste des actualités -->
  <div class="bg-white shadow-md rounded-lg overflow-hidden">
    <h2 class="text-xl font-semibold p-4 bg-gray-50 border-b">Liste des actualités</h2>
    
    <div class="divide-y">
      @for(actualite of actualites; track actualite.key) {
        <div class="p-4 flex items-center justify-between hover:bg-gray-50">
          <div class="flex-1">
            <h3 class="font-medium">{{ actualite.titre }}</h3>
            <div class="text-sm text-gray-500">
              Publié le {{ actualite.date | date:'longDate':'':'fr' }}
              @if(actualite.auteur) {
                par {{ actualite.auteur }}
              }
            </div>
          </div>
          
          <div class="flex gap-2">
            <button (click)="onEdit(actualite)"
                    class="px-3 py-1 text-sm font-medium text-blue-600 hover:text-blue-700 
                           hover:bg-blue-50 rounded-md transition-colors">
              <span class="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/>
                </svg>
                Modifier
              </span>
            </button>
            
            <button (click)="onDelete(actualite)"
                    class="px-3 py-1 text-sm font-medium text-red-600 hover:text-red-700 
                           hover:bg-red-50 rounded-md transition-colors">
              <span class="flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                </svg>
                Supprimer
              </span>
            </button>
          </div>
        </div>
      } @empty {
        <div class="p-4 text-center text-gray-500">
          Aucune actualité n'a été créée.
        </div>
      }
    </div>
  </div>
</div>

<!-- Update le form. -->